<template>
<div class="bg bg—start">
  <header-block cls="header__menu--start"/>
  <div class="container">
    <div class="start start--2">
      <h1 class="title">
        Тест «Насколько гармоничны ваши отношения с партнером»
      </h1>
      <p class="txt txt--start">
        Пройдите наш тест и узнайте, какие отношения сейчас между вами и
        что можно предпринять, чтобы стать ближе друг к другу.
      </p>
      <router-link to="/test/couple" class="button">
        Начать
      </router-link>
      <img src="@/assets/images/start_2.png"
        srcset="@/assets/images/start_2@2x.png 2x" alt="img" class="start__img start__img--2">
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'start-couple',
};
</script>
